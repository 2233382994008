<template>
  <div class="container">
    <group-edit-form
        payload-process="edit"
        :detailed-source-group="detailedSourceGroup"
    />
  </div>
</template>

<script>

import GroupEditForm from "@/components/baseGroups/groupEditForm";
import {mapGetters} from "vuex";
export default {
  name: "GroupEdit",
  components: {GroupEditForm},
  data() {
    return {
      isLoading: false
    };
  },
  computed: {
    ...mapGetters('source_groups', ['detailedSourceGroup'])
  },
  methods: {
    loadDetailedSourceGroup() {
      this.isLoading = true;
      this.$store.dispatch('source_groups/getSourceGroup', this.$route.params.id).then(() => {
        this.isLoading = false;
      })
    },
  },
  mounted() {
    this.loadDetailedSourceGroup();
  }
}
</script>

<style scoped>

</style>